.footer {
  background-color: transparent;
  color: #000;
  padding: 0px;
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: 0px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

.footer-links {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: center;
  gap: 0.5rem; /* Adjusted gap for smaller screens */
  margin-bottom: 1rem;
}

.footer-link {
  border-radius: 50px;
  padding: 5px 20px;
  color: #2563eb;
  font-size: 1.5rem; /* Default font size */
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.footer-link:hover {
  color: black;
  background-color: rgba(0, 123, 255, 0.2);
}

.footer-text {
  padding-top: 20px;
  font-size: 1rem;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer-link {
    font-size: 1.25rem; /* Smaller font size for tablet screens */
  }

  .footer-text {
    font-size: 0.875rem; /* Smaller font size for the text on tablet screens */
  }
}

@media (max-width: 640px) {
  .footer-link {
    font-size: 1rem; /* Smaller font size for phone screens */
    padding: 5px 15px; /* Adjust padding for smaller screens */
  }

  .footer-text {
    font-size: 0.75rem; /* Smaller font size for the text on phone screens */
  }
}
