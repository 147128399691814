/* Navbar.css */

.navbar {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.8); /* Transparent background with slight white tint */
  border-radius: 50px;
  padding: 10px 80px;
  backdrop-filter: blur(15px); /* Apply the blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: shadow for visual separation */
  transition: transform 0.3s ease, padding 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  width: 80%;
  max-width: 1200px;
}

/* Hover effect to scale the navbar */
.navbar:hover {
  transform: translateX(-50%) scale(1.05);
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbar-logo,
.navbar-location,
.navbar-links {
  color: #2563eb;
  flex: 1;
  text-align: center;

}

.navbar-location {
  font-size: 1.2rem;
  font-weight: bold;
  color: black; /* Accent color for the location text */
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-icon {
  margin-right: 8px; /* Space between icon and location text */
  color: inherit; /* Match the color of the location text */
}

.location-text {
  color: inherit;
}

.navbar-links {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}/* Navbar.css */

/* Existing CSS... */

.home-button {
  color: #2563eb;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 50px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  color: black;
  background-color: rgba(0, 123, 255, 0.2); /* Light hover effect matching the location text color */
}

/* New common hover effect class */
.hover-highlight {
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
}

.hover-highlight:hover {
  background-color: rgba(0, 123, 255, 0.2); /* Light hover effect matching the location text color */
  border-radius: 50px; /* Add border-radius for rounded effect */
  transform: scale(1.05); /* Expand the element slightly */
}

.footer-link {
  font-size: 1.5rem; /* Increase text size */
}

/* Fix for border-radius issue */
.hover-highlight::after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}

/* Existing CSS... */


.home-icon {
  margin-right: 5px; /* Space between icon and text */
}

.home-text {
  font-size: 1.2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar {
    padding: 5px 30px;
  }

  .navbar-logo a,
  .navbar-links a,
  .navbar-location {
    font-size: 1rem;
  }

  .navbar-links li {
    margin: 0 5px;
  }
}
