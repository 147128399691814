.about-section {
    background-color: rgba(0, 123, 255, 0.2);
    color: black;
    padding: 2rem;
    border-radius: 10px;
    border-color: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    margin-bottom: 0rem; /* Adjust this value if needed */
  }

  .projects-section {
    margin-bottom: 0; /* Ensure there's no extra margin */
  }
  
  .mb-12 {
    margin-bottom: 3rem; /* Adjust as needed */
  }
  

  .projects-section hr {
    border: 1px solid #ccc;
    width: 100%;
    margin: 1rem 0;
  }
  
  
  .portrait-img {
    width: 50%;
    max-width: none;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    transition: transform 0.3s;
    margin-right: 2rem; /* Increased space between image and text */
  }
  
  .portrait-img:hover {
    transform: scale(1.05);
  }
  
  .sports-section,
  .hobbies-section,
  .travel-section {
    background-color: #f1f5f9; /* Changed background color to white */
  }

  
  
  .sports-section h1,
  .hobbies-section h1,
  .travel-section h1,
  .about-section h1 {
    color: black; /* Tailwind blue-600 */
    font-weight: bold;
    text-align: center;
  }
  
  .sports-section p,
  .hobbies-section p,
  .travel-section p {
    text-align: center;
    font-size: 1.125rem; /* Adjust as needed */
    padding-left: 1rem; /* Add left padding */
    padding-right: 1rem; /* Add right padding */
  }
  
  .about-section {
    width: 90%; /* Adjust width for margin on the sides */
    max-width: 1200px; /* Max width for large screens */
  }
  